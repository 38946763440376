<template lang="html">
  <div class="component-wrapper">
    <Profile></Profile>
  </div>
</template>

<script>
import Profile from '@/components/auth/Profile.vue'
export default {
    components: {
        Profile
    },
    mounted() {
    }
}
</script>

<style lang="css">
</style>

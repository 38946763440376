<template lang="html">
    <div class="component-wrapper row">
        <div class="row">
            <p class="username">{{username}}</p>
        </div>
        <div id="similar-podcasts-container" class="col-md-6 mt-lg-5 col-xs-12">
            <div @click="showCollections = !showCollections" class="header-line">
                <p v-if="showCollections" class="collections-header">Collections▼</p>
                <p v-else-if="!showCollections" class="collections-header">Collections▲</p>
            </div>
            <div v-if="showCollections && playlists.results" class="playlist-wrapper">
                <div class="mb-2" v-for="(playlist, index) in playlists.results" :key="index">
                    <a :href="`/collections/${playlist.id}`" class="single-podcast-link">
                        <div class="single-playlist-link">
                            <div class="col-1 d-flex playlist-image-container">
                                <div class="playlist-image" :style="getPlaylistImageStyle(playlist)">
                                    <p class="playlist-image-text" :style="getPlaylistImageFontStyle(playlist)">
                                        {{getPlaylistInitials(playlist.name)}}
                                    </p>
                                </div>
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <p class="playlist-name col-11">
                                        {{playlist.name}}
                                    </p>
                                </div>
                                <div class="row d-md-flex d-lg-none">
                                    <small class="multiple-playlists-summary col-11" v-if="playlist.summary.length > 30">
                                        {{playlist.summary.slice(0, 30)}}...
                                    </small>
                                    <small class="multiple-playlists-summary col-11" v-else>
                                        {{playlist.summary}}
                                    </small>
                                </div>
                                <div class="row d-none d-lg-flex">
                                    <small class="multiple-playlists-summary col-11" v-if="playlist.summary.length > 60">
                                        {{playlist.summary.slice(0, 60)}}...
                                    </small>
                                    <small class="multiple-playlists-summary col-11" v-else>
                                        {{playlist.summary}}
                                    </small>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="playlist-wrapper" v-else-if="showCollections && !playlists.results">
                <p>{{username}} has not created any collections yet.</p>
            </div>
            <div v-if="this.relevantScores">
                <div class="genres-scores-container-container">
                    <div @click="showPreferences = !showPreferences">
                        <p v-if="showPreferences" class="user-preferences">Genre preferences▼</p>
                        <p v-else-if="!showPreferences" class="user-preferences">Genre preferences▲</p>
                    </div>
                    <div v-if="showPreferences && this.relevantScores.length > 0" class="genre-scores-container-profile d-none d-xl-block">
                        <div class="genre-scores-div">
                            <apexchart
                                    v-if="dataReady"
                                    width="380"
                                    height="auto"
                                    type="pie"
                                    :options="chartOptions"
                                    :series="this.relevantScores">
                            </apexchart>
                        </div>
                    </div>
                    <div v-else-if="showPreferences && !this.relevantScores.length > 0" class="genre-scores-container-profile d-none d-xl-block">
                        <p>Preferences will be calculated when {{username}} saves their first podcast.</p>
                    </div>
                </div>
                <div class="genres-scores-container-container">
                    <div v-if="showPreferences && this.relevantScores.length > 0" class="genre-scores-container-profile d-lg-block d-xl-none">
                        <div class="genre-scores-div">
                            <apexchart
                                    v-if="dataReady"
                                    width="310"
                                    height="auto"
                                    type="pie"
                                    :options="chartOptions"
                                    :series="this.relevantScores">
                            </apexchart>
                        </div>
                    </div>
                    <div v-else-if="showPreferences && !this.relevantScores.length > 0" class="genre-scores-container-profile d-lg-none d-xl-none">
                        <p>{{username}} has not created any collections yet.</p>
                    </div>
                </div>
            </div>
        </div>
        <div id="similar-podcasts-container" class="col-md-6 col-xs-12">
            <div id="similar-podcasts-on-page">
                <div class="d-flex">
                    <div class="mr-2 saved-or-wishlistens-button" @click="setSavedOrWishlistens('Favorite podcasts')">
                        <p>Favorites</p>
                    </div>
                    <div class="saved-or-wishlistens-button" @click="setSavedOrWishlistens('Wishlistens')">
                        <p>Wishlistens</p>
                    </div>
                </div>
                <SearchResults v-if="this.savedPodcasts"
                               :header="savedOrWishlistens"
                               :searchResultsData="this.savedOrWishlistensResults">
                </SearchResults>
            </div>
        </div>
    </div>
    <!-- <div v-else>
        <h5 class="you-must-be-logged-in">You must be logged in to view this page</h5>
    </div> -->

</template>

<script>
import SearchResults from '@/components/search_results/SearchResults.vue'
export default {
    components: {
        SearchResults
    },
    data() {
        return {
            username: window.location.pathname.split('/').pop(),
            showCollections: true,
            showPreferences: true,
            showSavedPodcasts: true,
            dataReady: false,
            userId: null,
            savedOrWishlistens: 'Favorite podcasts',
            savedPodcasts: {
                results: []
            },
            wishlistenedPodcasts: {
                results: []
            },
            playlists: {
                results: []
            },
            chartOptions: {
                theme: {
                    monochrome: {
                        enabled: true,
                        color: '#3c4b80',
                    }
                },
                legend: {
                    position: 'left',
                    fontSize: '10em',
                    labels: {
                        colors: ['#ffffff'],
                    },
                },
                dataLabels: {
                    position: 'top'
                },
                chart: {
                    type: 'pie',
                },
                labels: [],
                responsive: [{
                    breakpoint: 340,
                    options: {
                        chart: {
                            width: '50%'
                        },
                    }
                }]
            }
        }
    },
    computed: {
        nPodcastsSaved() {
            return this.savedPodcasts.results.length
        },
        statistics() {
            let stats = {'Horror': 0, 'Thriller': 0,
                         'Mystery': 0, 'SciFi': 0,
                         'Fantasy': 0, 'Crime': 0,
                         'Comedy': 0, 'Folklore': 0}
            for (let pod of this.savedPodcasts.results) {
                stats.Horror += pod.horror_score
                stats.Thriller += pod.thriller_score
                stats.Mystery += pod.mystery_score
                stats.SciFi += pod.sci_fi_score
                stats.Fantasy += pod.fantasy_score
                stats.Crime += pod.crime_score
                stats.Comedy += pod.comedy_score
                stats.Folklore += pod.folklore_score
            }
            return stats
        },
        labels() {
            return Array.from(Object.keys(this.statistics))
        },
        scores() {
            return Array.from(Object.values(this.statistics))
        },
        totalScores() {
            let summer = (previousValue, currentValue) => previousValue + currentValue
            return this.scores.reduce(summer)
        },
        relevantLabels() {
            return Object.keys(this.statistics).filter(prop => this.statistics[prop] > this.totalScores/10)
            // return Object.entries(this.statistics).filter(([, v]) => v > this.totalScores/10).map(([k]) => k);
        },
        relevantScores() {
            let scores = []
            for (let label in this.statistics) {
                if (this.relevantLabels.includes(label)) {
                    scores.push(this.statistics[label])
                }
            }
            return scores
            // return Object.values(this.statistics).filter(prop => this.relevantLabels.includes(this.statistics[prop]))
        },
        savedOrWishlistensResults() {
            if (this.savedOrWishlistens === 'Favorite podcasts') {
                return this.savedPodcasts
            } else {
                return this.wishlistenedPodcasts
            }
        }
    },
    methods: {
        setSavedOrWishlistens(toSet) {
            this.savedOrWishlistens = toSet
            this.$forceUpdate()
        },
        getProfile() {
            this.$http.get(`${this.$store.state.endpoints.baseUrl}users/${this.username}`, {
                // headers: {
                //     'Authorization': `JWT ${this.$store.state.jwt}`
                // }
            }).then((response) => {
                this.savedPodcasts.results = response.data.saved_podcasts
                this.wishlistenedPodcasts.results = response.data.wishlisten_podcasts
                this.userId = response.data.id
                this.chartOptions = {...this.chartOptions, ...{
                    labels: this.relevantLabels
                }}
                this.getPlaylists()
                this.dataReady = true
            }).catch((error) => {
                return error
            })
        },
        getPlaylists() {
            // if (this.$store.state.isAuthenticated) {
                this.$http.get(`${this.$store.state.endpoints.baseUrl}playlists/?user=${this.userId}&limit=100`).then((response) => {
                    this.playlists = response.data
                })
            // }
        },
        getPlaylistInitials(name) {
            name = name.split(' ')
            let wordsToRemove = ['a', 'the', 'of', 'in', 'at', 'but', 'by', 'for', 'to', 'and']
            let filteredName = name.filter(word =>
                                !wordsToRemove.includes(word)
                                && !wordsToRemove.includes(word.toUpperCase())
                                && !wordsToRemove.includes(word.toLowerCase())
                                && !wordsToRemove.includes(word.slice(0,1) + word.slice(1, word.length-1)))
            filteredName = filteredName.join(' ')
            return filteredName.split(' ').map((n)=>n[0].toUpperCase()).join('').slice(0,3)

        },
        getPlaylistImageBackgroundColor(playlist) {
            let colors = ['#3b3b3b', '#3c2222', '#222c3c', '#223c2b', '#3a223c']
            let summaryLength = playlist.summary.length
            let index = summaryLength % colors.length
            return colors[index]
        },
        getPlaylistImageFontColor(playlist) {
            let colors = ['#7afafe', '#fe7a7a', '#84fe7a','#fbfe7a', '#807afe']
            let nameLength = playlist.name.length
            let index = nameLength % colors.length
            return colors[index]
        },
        getPlaylistImageStyle(playlist) {
            return `background:${this.getPlaylistImageBackgroundColor(playlist)};
                    margin-left: auto;
                    height: 3em;
                    width: 3em;
                    position: absolute;
                    left: 0em;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    border-radius: 0.2em;`
        },
        getPlaylistImageFontStyle(playlist) {
            return `align-self: center;
                    font-size: 1.5em;
                    color: #84fe7a;
                    font-weight: bolder;
                    cursor: default;
                    color:${this.getPlaylistImageFontColor(playlist)};`
        }
    },
    mounted() {
        document.title = `${this.$store.state.title} - ${this.username}`
        this.getProfile()
    }
}
</script>

<style lang="css" scoped>
p {
    color: white;
}
#similar-podcasts-on-page {
    height: 80vh;
    overflow-y: scroll;
    display: inline-block;
    padding: 20px;
    scrollbar-width: none;
}
#similar-podcasts-on-page::-webkit-scrollbar {
    display: none;
}
.genre-scores-container-profile {
    box-shadow: var(--globalButtonShadow);
    border-radius: 1em;
    padding: 1em;
}
.single-playlist-link {
    padding: 0.5em;
    display: flex;
    cursor: pointer;
    text-decoration: none;
    color: white;
    border-radius: 20px;
    box-shadow: var(--globalButtonShadow);
    max-height: 6em;
    min-height: 6em;
}
.single-playlist-link:hover {
    background: var(--hoverColor);
    color: white;
}
.single-podcast-link {
    text-decoration: none !important;
    margin-bottom: 1em;
}
.playlists-in-profile {
    max-height: 45vh;
}
.playlist-wrapper {
    max-height: 45vh;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 1em;
}
.playlist-wrapper::-webkit-scrollbar {
    display: none;
}
.username {
    font-size: 1.5em;
    margin-bottom: 1em;
    padding-left: 1.5em;
}
.genres-scores-container-container {
    padding: 1em;
}
.user-preferences {
    font-size: 1.5em;
    cursor: pointer;
}
.header-line {
    cursor: pointer;
    padding: 1em;
    font-size: 1em;

}
.collections-header {
    font-size: 1.5em;
}
.playlist-image-container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 0.2em;
}
.multiple-playlists-summary {
    align-self: center;
    word-break: break-word;
    overflow-wrap: break-word;
}
.saved-or-wishlistens-button {
    margin-right: 0.4em;
    background: var(--lightBackground);
    width: 6em;
    border-radius: 0.4em;
    padding: 0.4em;
    display: flex;
    justify-content: center;
    box-shadow: var(--globalButtonShadow);
    margin-bottom: 1em;
    cursor: pointer;
    color: white;
}
.you-must-be-logged-in {
    color: white;
}
</style>
